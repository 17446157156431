<script>
import Stepper from '@comp/stepper/Stepper.vue'
import StepZoneContent from './zone/StepZoneContent.vue'
import SelectSourceStepContent from './selectSource/SelectSourceStepContent.vue'
import StepLineContent from './line/StepLineContent.vue'
import StepLaunchAnalysisContent from './launchAnalysis/StepLaunchAnalysisContent.vue'
import { point, distance } from '@turf/turf'

const MAX_WIDTH_KM = 1000
const MAX_HEIGHT_KM = 1000

export default {
  name: 'CreateAnalysisStepper',
  components: {
    Stepper,
    StepZoneContent,
    SelectSourceStepContent,
    StepLineContent,
    StepLaunchAnalysisContent,
  },
  props: {
    bbox: {
      type: Array, // Long, Lat, Long, Lat
      default: null,
    },
    currentStep: {
      type: String,
      required: true,
    },
    geojsonValidationError: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    matchingDataSources: {
      type: Array,
      required: true,
    },
    ready: {
      type: Boolean,
      required: true,
    },
    runningGeojsonValidation: {
      type: Boolean,
      default: false,
    },
    selectedDataSource: {
      type: Object,
      default: () => null,
    },
    possibleIndicators: {
      type: Array,
      required: true,
    },
    selectedIndicators: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'selectedDataSourceUpdated',
    'selectedIndicatorsUpdated',
    'outputFilesUpdated',
    'goToStep',
    'createTask',
    'validateGeojsonFile',
  ],
  data () {
    return {
      steps: [
        'zone',
        'select-source',
        'line',
        'launch',
      ],
    }
  },
  computed: {
    selectedZoneIsTooBig () {
      if (this.bbox) {
        const [lon1, lat1, lon2, lat2] = this.bbox
        const meanLongitude = (lon1 + lon2) / 2
        const meanLatitude = (lat1 + lat2) / 2
        return !(
          this.isDistanceWithin(meanLongitude, lat1, meanLongitude, lat2, MAX_WIDTH_KM)
          && this.isDistanceWithin(lon1, meanLatitude, lon2, meanLatitude, MAX_HEIGHT_KM)
        )
      } else {
        return false
      }
    },
    selectedZoneIsOutOfDataSources () {
      return !!(this.bbox && !this.matchingDataSources.length) && this.ready
    },
    zoneStepIsValid () {
      return !!(
        this.bbox
        && !this.selectedZoneIsTooBig
        && !this.selectedZoneIsOutOfDataSources
        && this.ready
      )
    },
    isNextStepMessage () {
      return this.lines ? !!(this.lines.length) : false
    },
    analysisCreationIsDisabled () {
      return !(this.selectedIndicators.length)
    },
    dataSourceIsSelected () {
      return !!this.selectedDataSource
    },
  },
  watch: {
    bbox: {
      async handler (newValue, oldValue) {
        if (!!newValue && !!oldValue) {
          if (!newValue.every((v, i) => v === oldValue[i])) {
            this.$emit('selectedDataSourceUpdated', null)
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    isDistanceWithin (lon1, lat1, lon2, lat2, distanceKm) {
      const pointA = point([lon1, lat1])
      const pointB = point([lon2, lat2])
      const lineDistanceKm = distance(pointA, pointB, 'kilometers')
      return lineDistanceKm <= distanceKm
    },
    onSourceSelected (dataSource) {
      this.$emit('selectedDataSourceUpdated', dataSource)
    },
    selectedIndicatorsUpdated (indicators) {
      this.$emit('selectedIndicatorsUpdated', indicators)
    },
    outputFilesUpdated (outputFiles) {
      this.$emit('outputFilesUpdated', outputFiles)
    },
    goToStep (step) {
      this.$emit('goToStep', step)
    },
    createTask () {
      this.$emit('createTask')
    },
    validateGeojsonFile (file) {
      this.$emit('validateGeojsonFile', file)
    },
  },
}
</script>
<template>
  <Stepper
    :current-step="currentStep"
    :steps="steps"
    class="card"
  >
    <!-- STEP 1 - ZONE -->
    <template #header-zone>
      {{ $gettext('Choose the area you want to analyze') }}
    </template>
    <template #content-zone>
      <StepZoneContent
        :selected-zone-is-too-big="selectedZoneIsTooBig"
        :selected-zone-is-out-of-data-sources="selectedZoneIsOutOfDataSources"
      />
    </template>
    <template #footer-zone>
      <v-btn
        color="primary"
        :disabled="!zoneStepIsValid"
        @click="goToStep('select-source')"
      >
        <template v-slot:prepend>
          <v-icon v-if="ready">
            fas fa-arrow-right
          </v-icon>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
            :size="20"
            :width="4"
          />
        </template>
        {{ $gettext('Next step') }}
      </v-btn>
    </template>
    <!-- STEP 2 - SELECT SOURCE -->
    <template #header-select-source>
      {{ $gettext('Select source of data') }}
    </template>
    <template #content-select-source>
      <SelectSourceStepContent
        :data-sources="matchingDataSources"
        :selected-source="selectedDataSource"
        @source-selected="onSourceSelected"
      />
    </template>
    <template #footer-select-source>
      <v-btn
        color="primary"
        prepend-icon="fas fa-arrow-right"
        :disabled="!dataSourceIsSelected"
        @click="goToStep('line')"
      >
        {{ $gettext('Next step') }}
      </v-btn>
      <v-btn
        prepend-icon="fas fa-arrow-left"
        @click="goToStep('zone')"
      >
        {{ $gettext('Previous') }}
      </v-btn>
    </template>
    <!-- STEP 3 - LINE -->
    <template #header-line>
      {{ $gettext('Add lines (optional)') }}
    </template>
    <template #content-line>
      <StepLineContent
        :running-geojson-validation="runningGeojsonValidation"
        :geojson-validation-error="geojsonValidationError"
        @validate-geojson-file="validateGeojsonFile"
      />
    </template>
    <template #footer-line>
      <v-btn
        color="primary"
        prepend-icon="fas fa-arrow-right"
        @click="goToStep('launch')"
      >
        <span
          v-if="isNextStepMessage"
        >
          {{ $gettext('Next step') }}
        </span>
        <span v-else>
          {{ $gettext('Skip step') }}
        </span>
      </v-btn>
      <v-btn
        prepend-icon="fas fa-arrow-left"
        @click="goToStep('select-source')"
      >
        {{ $gettext('Previous') }}
      </v-btn>
    </template>
    <!-- STEP 4 - LAUNCH -->
    <template #header-launch>
      {{ $gettext('Launch your analysis') }}
    </template>
    <template #content-launch>
      <StepLaunchAnalysisContent
        :data-source="selectedDataSource"
        :indicators="possibleIndicators"
        @selected-indicators-updated="selectedIndicatorsUpdated"
        @output-files-updated="outputFilesUpdated"
      />
    </template>
    <template #footer-launch>
      <v-btn
        color="primary"
        :disabled="analysisCreationIsDisabled"
        prepend-icon="fas fa-globe"
        @click="createTask"
      >
        {{ $gettext('Launch the analysis') }}
      </v-btn>
      <v-btn
        prepend-icon="fas fa-arrow-left"
        @click="goToStep('line')"
      >
        {{ $gettext('Previous') }}
      </v-btn>
    </template>
  </Stepper>
</template>
<style lang="scss">
.indications {
  background-color: $grey-ultralight;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1ch;
}
.result-indication {
  font-weight: bold;
}
</style>
