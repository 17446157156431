import locales from './locales.json'
import colorShades from '@scss/sds-design-system/color-shades'

export const DEFAULT_LANGUAGE = locales.default
export const LANGUAGES = locales.languages
export const STATUS_COLORS = {
  GREEN: colorShades.green,
  ORANGE: colorShades.orange,
  RED: colorShades.red,
  GREY: colorShades.grey_dark,
}
export const RESULTS_REFRESH_MS = 10 * 1000

// COORDINATES [Lon, Lat]
export const COORDINATES_FRANKFURT = [8.6, 50.1]
export const COORDINATES_ORIGIN = COORDINATES_FRANKFURT

export const GEOJSON_SIZE_LIMIT = 1024 ** 2
