import gql from 'graphql-tag'

export const allTasksQuery = gql`
query {
  allTasks (
    order: {creationDatetime: DESC}
  ) {
    id
    creationDatetime
    expirationDatetime
    status
    pendingInfo
    resultUrl
  }
}
`
export const allDataSources = gql`
query allDataSources {
  allDataSources {
    code
    name
    description
    boundingPolygon
  }
}
`
export const getLineStringFromGeojson = gql`
query getLineStringFromGeojson (
  $geojsonFile: Upload!
) {
  getLineStringFromGeojson(geojsonFile: $geojsonFile) {
    geojson
  }
}
`
export const getMatchingDataSourcesForBbox = gql`
query getMatchingDataSourcesForBbox (
  $south: Latitude!
  $west: Longitude!
  $north: Latitude!
  $east: Longitude!
) {
  getMatchingDataSourcesForBbox(
    box: {
      southWest: {
        longitude: $west
        latitude: $south
      },
      northEast: {
        longitude: $east
        latitude: $north
      }
    }
  ) {
    code
    name
    description
    boundingPolygon
  }
}
`
export const allIndicators = gql`
query allIndicators (
  $dataSource: DataSourceEnum!
) {
  allIndicators(dataSource: $dataSource) {
    code
    name
    definition
    source
    horizons {
      name
      isRef
      period
    }
  }
}
`
