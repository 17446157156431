class DataSource {
  constructor (code, name, description, color) {
    this.code = code
    this.name = name // filled by the backend but used for translation
    this.description = description // filled by the backend but used for translation
    this.boundingPolygon = [] // filled by the backend
    this.color = color // used in the map
  }
}

const $gettext = s => s // for easygettext to peek translations

const dataSources = [
  new DataSource(
    'AFR',
    $gettext('Copernicus AFR'),
    $gettext('Domain dataset for Africa zone. 21 AFR-CORDEX models for RCP 4.5 and 31 for RCP 8.5.'),
    '#2C7FB8', // blue
  ),
  new DataSource(
    'AUS',
    $gettext('Copernicus AUS'),
    // eslint-disable-next-line max-len
    $gettext('Domain dataset for Austral Asia zone. 13 AUS-CORDEX models for RCP 4.5 and 22 for RCP 8.5.'),
    '#FEB24C', // yellow
  ),
  new DataSource(
    'EUR',
    $gettext('Copernicus EUR'),
    // eslint-disable-next-line max-len
    $gettext('Domain dataset for Europe zone. 20-21 EUR-CORDEX models for RCP 4.5 and 48-50 for RCP 8.5.'),
    '#41B6C4', // turquoise
  ),
  new DataSource(
    'EUR_CMIP5',
    $gettext('Copernicus EUR CMIP 5'),
    $gettext('Domain dataset for Europe zone. 8-10 CMIP 5 models.'),
    'transparent', // because it’s the same area as 'EUR'
  ),
  new DataSource(
    'FR',
    $gettext('DRIAS'),
    $gettext('Domain dataset for France. 10 models for RCP 4.5 and 12 for RCP 8.5.'),
    '#FD8D3C', // light orange
  ),
  new DataSource(
    'SEA',
    $gettext('Copernicus SEA'),
    $gettext('Domain dataset for South East Asia zone. 4-5 SEA-CORDEX models for RCP 4.5 and 11-12 for RCP 8.5.'),
    '#A1DAB4', // green
  ),
  new DataSource(
    'WAS',
    $gettext('Copernicus WAS'),
    $gettext('Domain dataset for South Asia zone. 11-19 WAS-CORDEX models for RCP 4.5 and 20-26 for RCP 8.5.'),
    '#BD0026', // red
  ),
]
const dataSourceMap = new Map(dataSources.map(ds => [ds.code, ds]))
export default dataSourceMap
