<script>
import IndicatorGroup from './IndicatorGroup.vue'
import indicatorMap from '@src/utils/indicators'

export default {
  name: 'IndicatorsPanel',
  components: {
    IndicatorGroup,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => null,
    },
    indicators: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'selectedIndicatorsUpdated',
  ],
  data () {
    return {
      selectableIndicators: [],
    }
  },
  computed: {
    tempIndicators () {
      return this.selectableIndicators.filter(i => i.group == 'temp')
    },
    windIndicators () {
      return this.selectableIndicators.filter(i => i.group == 'wind')
    },
    selectedIndicators () {
      return this.selectableIndicators.filter(indicator => indicator.selected)
    },
  },
  watch: {
    indicators: {
      handler (indicators) {
        this.selectableIndicators = indicators.map(i => ({
          ...i,
          group: indicatorMap.get(i.code).group,
          selected: true,
        }))
      },
      immediate: true,
    },
    selectedIndicators () {
      this.$emit('selectedIndicatorsUpdated', this.selectedIndicators)
    },
  },
}
</script>
<template>
  <div class="data-panel">
    <div class="data-panel-title">
      {{ $gettext('Indicators') }}
    </div>
    <div class="data-panel-content">
      <IndicatorGroup
        :name="$gettext('Temperatures')"
        icon="fas fa-temperature-low"
        :indicators="tempIndicators"
      />
      <IndicatorGroup
        :name="$gettext('Wind and precipitations')"
        icon="fas fa-cloud-showers-heavy"
        :indicators="windIndicators"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.data-panel-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>
